<template>
<div style="min-width:100%;min-height:100%;background:#E6E6E6">
    <div class="container p-3">
      <div class="row justify-content-center mt-5 p-3">
          <div class="col-sm-10 col-md-8 col-lg-7 col-12">
                <div class="row mt-5" style="box-shadow: 0px 0px 2px 2px grey;
    padding: 15px; border-radius: 15px;background: #fff;z-index: 1;
    overflow: hidden;">
            <div class="col-md-5 col-lg-5 d-sm-none d-md-block d-none">
                 <img class="img-fluid mt-5" src="@/assets/sa/img/book-now-page.jpg"/>
            </div>
        <div class="col-md-7 col-lg-7 col-sm-10">
            <div class="p-md-5 p-md-2 p-sm-1">
        
        <h1 class="text-center">
            <a href="https://code.tinker.ly" target="_blank">
            
              <img
                src="@/assets/india/image/logo.png"
                class="px-4 py-2 img-fluid"
                style="
                  border: 1px solid black;
                  border-radius: 10rem;
                  max-width: 180px;
                  width: 55%;
                  min-width: 100px;
                "
              />
              
              </a>
        </h1>
        
       <div class="row justify-content-center mt-5">
           <div class="col-12">
               <Form  @submit="onSubmit" :validation-schema="schema" >
                   <div class="form-group mb-3">
                       <label class="text-muted text-center">فضلاً أدخل رقم الجوال لاستلام
رمز التفعيل</label>
                       <vue-tel-input style="height:45px;" :autoDefaultCountry=false :defaultCountry="$store.state.country" @close="MobileNumberfocus"  @input ="MobileNumberChange" @focus="MobileNumberfocus"  mode="international" :inputOptions="Mobileopt" validCharactersOnly=true>

                        </vue-tel-input>
                      
                           <p v-if="!ismobilevalid && ismobileFocus" class="text-danger" name="Mobile">Mobile number is not valid</p>
                   </div>
                   <div class="form-group mb-3">
                     
                       <Field type="text" @change="setOTPblank" class="form-control" name="Name" placeholder="Student Name"/>
                           <ErrorMessage class="text-danger" name="Name" />
                   </div>
                    <div class="form-group mb-3">
                  <Field
                    type="text"
                    class="form-control"
                    name="Email"
                    placeholder="عنوان البريد الإلكتروني للوالدين"
                    @change="setOTPblank"
                  />
                  <ErrorMessage class="text-danger" name="Email" />
                </div>
                   <div style="min-height:220px" v-if="sendOtp">
                       <p class="text-center m-0"><label class="text-muted">فضلاً أدخل الرمز</label></p>
                   <p class="text-center m-0"> 
          <input  class="partitioned text-center" v-model="otp1" v-mask="'#'"  v-on:keyup="gonext(0,$event)"   type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp2" v-mask="'#'" v-on:keyup="gonext(1,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp3" v-mask="'#'"  v-on:keyup="gonext(2,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp4" v-mask="'#'" v-on:keyup="gonext(3,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp5" v-mask="'#'"  v-on:keyup="gonext(4,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp6" v-mask="'#'" v-on:keyup="gonext(5,$event)"  type="text" maxlength="1" />
          </p>
           <p v-if="otperror" class="text-danger text-center">{{otperror}}</p>
      <p class="text-center m-0 mt-5">لم تستلم الرسالة؟</p>
     <p class="text-center m-0"><button  type="button" @click="resendOtp" class="btn btn-link">إعادة إرسال</button></p>
                   </div>
                   <div style="min-height:220px" v-else>
                   </div>
                   <p class="text-center">
                       <button type="submit" style="height:45px" class="btn  btn_orange_active">التحقق والاستمرار</button>
                   </p>
                   <p class="mt-3" style="font-size: 0.8rem;">By registering here, I agree to Tinkerly <a target="_Blank" href="https://tinker.ly/terms-and-conditions/">Terms & Conditions</a> and <a target="_Blank" href="https://tinker.ly/privacy-policy-for-tinkerly/">Privacy Policy</a></p>
                   
               </Form >
           </div>
       </div>
      
       <div class="modal " v-if="isLoading" style="display:block;background:rgba(0,0,0,0.2)">
  <div class="modal-dialog modal-dialog-centered" style="height: 85px !important;width: 85px !important;" role="document">
    <div class="modal-content">
      <div class="modal-body">
     <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
      </div>
     
    </div>
  </div>
</div>
    </div>
        </div>
        </div>
          </div>
      </div>
    </div>
</div>
</template>
<script>
const leadOptions = {headers: {'Content-Type': 'application/json'}};
const leadURL = "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r33f4bf885fac45bf9fd2cb770d2cb54e&secretKey=63c3b1df9c6a723e3dd5bdc663b27b8f6aca7158";
    import '@/assets/css/rtl.bootstrap.min.css'
    import '@/assets/Font/rtl.css'
    import {mask} from 'vue-the-mask'
    import { Form, Field, ErrorMessage } from 'vee-validate';
   import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import * as yup from 'yup';
import {getMXCProspectId} from '@/assets/js/TinkerlyTracking.js';
    export default {
        name: 'BookDemo',
         directives: {mask},
          components: {
    Form,
    Field,
    ErrorMessage,
    VueTelInput
  },
  data() {
    const schema = yup.object({
      Name: yup.string().required(),
       Email: yup.string().when('otp',{
        is: ()=> this.cnt!='IN',
         then: yup.string().email().required(),
      }),
    });

    return {
      schema,
      sendOtp:false,
      otp1:'',
      otp2:'',
      otp3:'',
      otp4:'',
      otp5:'',
      otp6:'',
      otperror:'',
      cnt :'',
      isLoading:false,
      otpenc :'',
        Mobile:'',
      ismobilevalid:true,
      ismobileFocus:false,
      Mobileopt:{
        id:"Mobile",
        name : "Mobile",
        maxlength:18,
        required:true,
        placeholder:"Mobile Number"
      }
    };
  },
  methods: {
        MobileNumberChange(num,data) {
      if(this.ismobileFocus){
      this.ismobilevalid = data.valid;
      if(data.valid === true)
      this.Mobile = "+"+data.countryCallingCode+"-"+data.nationalNumber;
      }
      console.log(data);
     },
     MobileNumberfocus(){
       console.log("focus");
       this.ismobileFocus = true;
       if(this.Mobile.length<5 && this.ismobilevalid)
       this.ismobilevalid = false;
     },
      onsubmitSendOtp(value){
           if(!this.ismobileFocus || !this.ismobilevalid || this.Mobile.length<8 )
      {
        this.ismobilevalid = false;
        this.ismobileFocus = true;
        return;
      }
 this.isLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append('Mobile', this.Mobile);
        if(value.Email != undefined)
        bodyFormData.append("Email", value.Email);
        this.axios.post('https://alphalt.tinker.ly/api/V1/SendOtpEnc',bodyFormData).
        then((response) => {
                 return response.data
                        
            })
            .then(data => {
               this.otpenc = data.data;
   this.sendOtp = true;
      this.isLoading = false;
      var d =null;
          if(value.Email == undefined)
          d = [{"Attribute":"Phone","Value":this.Mobile},
         {"Attribute":"FirstName","Value":value.Name},
         {"Attribute":"mx_otp_verify","Value":"No"},
         {"Attribute":"mx_Lead_Type","Value":'Int. User'},
         {"Attribute":"Source","Value":'Web-SA-ar'}
         ];
         else d = [{"Attribute":"Phone","Value":this.Mobile},
         {"Attribute":"FirstName","Value":value.Name},
         {"Attribute":"mx_otp_verify","Value":"No"},
         {"Attribute":"mx_Lead_Type","Value":'Int. User'},
         {"Attribute":"Source","Value":'Web-SA-ar'},
         {"Attribute":"EmailAddress","Value":value.Email},
         ];
          if(getMXCProspectId()!=null)
           d.push({ Attribute: "ProspectID", Value: getMXCProspectId() });
     this.axios.post(leadURL,d,leadOptions);
  })
  .catch(error => {
     console.log(error);
    this.isLoading = false;
  });
     
      },
    onSubmit(values) {
       
       if(this.sendOtp)
       this.submitOtp(values);
       else
       this.onsubmitSendOtp(values);
    },
    gonext(e,event){
       var alls = document.getElementsByClassName("partitioned");
       if(event.target.value.trim().length == 0  && e==0){
           alls[0].focus();
           alls[0].select();
       }

       else if(event.target.value.trim().length == 0 && (event.key=="Backspace" || event.key=="Delete") && e>0)
        {
            alls[e-1].focus();
             alls[e-1].select();
        }
        else if(event.target.value.trim().length >0 && e<5){
            alls[e+1].focus();
             alls[e+1].select();
        }
    },
    hideBox(){
this.sendOtp = false;
    },
    resendOtp(){
        this.isLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append('Mobile', this.Mobile);
        this.axios.post('https://alphalt.tinker.ly/api/V1/SendOtpEnc',bodyFormData).
        then((response) => {
                 return response.data
                        
            })
            .then(data => { this.otpenc = data.data;
   this.sendOtp = true;
      this.isLoading = false;
  })
  .catch(error => {
     console.log(error);
    this.isLoading = false;
  });
    
     
    },
    submitOtp(values){
        
        var otp = ""+this.otp1+this.otp2+this.otp3+this.otp4+this.otp5+this.otp6;
        if(otp.length!=6)
        this.otperror="Please enter a valid OTP";
        else{
            this.otperror="";
            this.isLoading = true;
             var bodyFormData = new FormData();
        bodyFormData.append('Mobile', this.Mobile);
        bodyFormData.append('Name', values.Name);
        bodyFormData.append('Email', values.Email);
        bodyFormData.append('Auth', this.otpenc);
        bodyFormData.append('Otp', otp);
        bodyFormData.append('Operation', 2);
        bodyFormData.append('Refer',this.$route.params.username );
        bodyFormData.append('CCode',this.cnt);
             this.axios.post('https://alphalt.tinker.ly/api/V1/LoginViaCode',bodyFormData).
             then((response) => {
                 return response.data
                        
            })
            .then(data => {
             this.$gtag.event('conversion', {
          'send_to': 'AW-810557807/-D1ECN7mt_kCEO_CwIID'
      });

      var d =null;
          if(values.Email == undefined)
          d = [{"Attribute":"Phone","Value":this.Mobile},
         {"Attribute":"FirstName","Value":values.Name},
         {"Attribute":"mx_otp_verify","Value":"Yes"},
         {"Attribute":"mx_Lead_Type","Value":'Int. User'},
         {"Attribute":"Source","Value":'Web-SA-ar'}];
          else
          d = [{"Attribute":"Phone","Value":this.Mobile},
         {"Attribute":"FirstName","Value":values.Name},
         {"Attribute":"mx_otp_verify","Value":"Yes"},
         {"Attribute":"mx_Lead_Type","Value":'Int. User'},
         {"Attribute":"Source","Value":'Web-SA-ar'},
         {"Attribute":"EmailAddress","Value":values.Email}];
          if(getMXCProspectId()!=null)
           d.push({ Attribute: "ProspectID", Value: getMXCProspectId() });
     this.axios.post(leadURL,d,leadOptions).then(()=>{
 setInterval(function () {
         window.location.href = "https://alpha.letstinker.tinker.ly/bypass_login?id="+data.data;
                this.sendOtp = true;
                    this.isLoading = false;
    }, 2000);
  
  });
  })
  .catch(error => {
      this.isLoading = false;
      if(error.response.data.status.code==301)
      this.otperror="Invalid OTP";
  });
        }
    },
    setOTPblank(){
        this.sendOtp = false;
            this.otp1='';
            this.otp2='';
            this.otp3='';
            this.otp4='';
            this.otp5='';
            this.otp6='';
    }
  },
  beforeMount: function () {
       this.cnt = this.$store.state.country;
        
    },
  mounted: function(){
  window.__lc = window.__lc || {};
  window.__lc.license = 13188978;
  (function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}
  var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){
  i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},
  get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");
  return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){
  var n=t.createElement("script");
  n.async=!0,n.type="text/javascript",
  n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};
  !n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
    }
};
</script>
<style scoped>
.vti__dropdown-list{
  left: auto;
  text-align: right;
}
.partitioned {
  padding: 10px 15px;
  border: 2px solid #D1D1D1;
  border-radius: 5px;
  margin: 10px;
  width: 45px;
}
input.partitioned:focus{
    outline: none;
    border: 2px solid #FF9933;
}
.btn-check:focus + .btn, .btn:focus{
    box-shadow: none;
}
.btn_orange_active {
        background: #FF9933;
        color:#fff;
        border:2px #FF6633 solid;
    }
    * {
        font-family: pnu;  
    }
    .form-control:focus{
        box-shadow: none;
    }
  .form-control{
      height: 45px;
      border: 2px solid #D1D1D1;
  }
    


.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
    display: inline-block;
    padding: 10px 20px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    color: #000;
    border: 2px solid #D1D1D1;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    height: 45px;
}

.radio-toolbar label:hover {
  background-color: #FF9933;
  border-color: #FF6633;
}



.radio-toolbar input[type="radio"]:checked + label {
   background-color: #FF9933;
  border-color: #FF6633;
}
</style>
